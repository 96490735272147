(function(){
    require('../../components/headroom.js/dist/headroom.min');
    require('../../components/headroom.js/dist/jQuery.headroom.min');

    var $nav = $('#menu-primary');

    $wrap = $('<div>').css('min-height', $nav.outerHeight() );

    $nav.wrap( $wrap ).headroom({
        offset : $nav.offset().top
    });

    $(document).on('hashnavigate', function(){
        $('#menu-toggle').trigger('click');
    });

})();
