require('./vendor/console-stub');

require('../components/matchMedia/matchMedia');
require('../components/matchMedia/matchMedia.addListener');
require('../components/bootstrap/js/transition');
require('../components/bootstrap/js/collapse');

require('./libs/show-more.js');
var hashTranslator = require('./libs/hash-translator');
hashTranslator.init();

require('./app/navigation.js');


(function(){
    require('./vendor/slick');
    var $ = require("./../components/jquery/dist/jquery.js");
    var ScrollShow = require('./libs/scrollshow.js');

    if( !$('#about').length ) return;

    var hero = '#about .hero__inner';
    var isSlickInit = false;
    var ss = new ScrollShow();

    function setSize(){
        var height = window.innerHeight ? window.innerHeight : $(window).height();

        if(height < 450) height = 450;

        $('#about .hero__slide').css('height', height);
    }

    function handleMediaChange( mql ){
        if ( mql.matches ) {
            if( isSlickInit ) {
                $(hero).slick('unslick');
                isSlickInit = false;
            }

            ss.init();
        } else {
            ss.destroy();

            $(hero).slick({
              dots: true,
              speed: 500,
              autoplay: true,
              autoplaySpeed: 8000
            });

            isSlickInit = true;
        }
    };

    $(window).on('resize.slickswitch', setSize);

    setSize();

    var mql = window.matchMedia( '(min-width: 71.25em)' ); // 992px
    mql.addListener( handleMediaChange );
    handleMediaChange( mql );
})();
